<template>
    <Layout>
    <PageHeader :title="title" />
    <div class="row">
      <div class="col-xl-12 content_margin_bottom">
        <Stat />
      </div>
    </div>
  </Layout>
</template>

<script>
import Layout from "../layout/index.vue";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import Stat from './widget.vue'

export default {
components: {
  Layout,
  PageHeader,
  Stat
},
page: {
        title: "Entity Admin",
        meta: [{ name: "description", content: appConfig.description }]
},
data() {
  return {
      title: "Dashboard",
      items: [
        {
          text: "Entity Admin",
        },
        {
          text: "Dashboard",
          active: true,
        },
      ],
    };
  },
  
}
</script>

<style>

</style>
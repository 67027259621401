<script>
import store from "@/state/store";
/**
 * Widget Component
 */
export default {
  data() {
    return {
      copied: false,
      courses: [],
      entityData: [],
    };
  },
  computed: {
    currentEntity() {
      return store.getters["auth/currentEntity"];
    },
  },
  methods: {
    copyText() {
      this.copied = true;
      navigator.clipboard.writeText(this.currentUser.afcfta_number);
    },
  },
  mounted() {
    store
      .dispatch("course/fetchDashboardData", this.$route.params.id)
      .then((response) => {
        this.entityData = response.data;
      })
      .catch((err) => {
        console.log(err, "errors");
      });

    store
      .dispatch("course/fetchCourses", this.$route.params.id)
      .then((response) => (this.courses = response.data.course));
  },
  created() {
    //fetch data providers
  },
};
</script>
<style scoped>
.copy {
  border: 1px solid rgb(47, 47, 146);
  outline: none;
  padding-right: 0.5em;
  padding-left: 0.5em;
  background: transparent;
  display: inline;
  margin-left: 1em;
  padding-bottom: 0 !important;
  padding-top: 0 !important;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}
.afcfta_number {
  display: inline;
}
@media screen and (max-width: 400px) {
  .widget_container {
    margin-bottom: 1em;
  }
}
</style>
<template>
  <div class="row widget_container">
    <div class="col-md-6 col-xl-4">
      <div class="card">
        <div class="card-body">
          <div class="media py-2">
            <div class="media-body overflow-hidden">
              <p class="text-truncate font-size-14 mb-4">
                Entity AfCFTA Number
              </p>
              <h4 class="afcfta_number">{{ currentEntity.afcfta_number }}</h4>
              <p
                type="button"
                class="copy"
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                :title="copied ? 'copied!' : 'copy to the clipboard'"
                @click="copyText"
              >
                {{ copied ? "copied!" : "copy" }}
              </p>
            </div>
            <div class="text-primary">
              <i class="far fa-id-badge font-size-24"></i>
            </div>
          </div>
        </div>

        <div class="card-body border-top py-3">
          <div class="text-truncate">
            <span
              v-if="currentEntity.verified_afcfta_number"
              class="text-light ml-2  badge rounded-pill bg-success p-2 font-size-11"
              >AfCFTA Number is Verified</span
            >
            <span
              v-if="!currentEntity.verified_afcfta_number"
              class="text-light ml-2  badge rounded-pill bg-danger p-2 font-size-11"
              >AfCFTA Number is Not Verified</span
            >
          </div>
        </div>
      </div>
    </div>

    <div class="col-md-6 col-xl-4">
      <router-link :to="`/entity/${$route.params.id}/courses`">
        <div class="card">
          <div class="card-body">
            <div class="media py-2">
              <div class="media-body overflow-hidden">
                <p class="text-truncate font-size-14 mb-4">
                  My Courses
                </p>
                <h4 class="afcfta_number">{{ courses.length }}</h4>
              </div>
              <div class="text-primary">
                <i class="fas fa-graduation-cap font-size-24"></i>
              </div>
            </div>
          </div>

          <div class="card-body border-top py-3">
            <div class="text-truncate">
              <span
                class="text-light ml-2  badge rounded-pill bg-info p-2 font-size-11"
                >All Created Courses</span
              >
            </div>
          </div>
        </div>
      </router-link>
    </div>

    <div class="col-md-6 col-xl-4">
      <div class="card">
        <div class="card-body">
          <div class="media py-2">
            <div class="media-body overflow-hidden">
              <p class="text-truncate font-size-14 mb-4">
                Number of Enrolled Users
              </p>
              <h4 class="afcfta_number">
                {{ entityData[0].total_enrollments }}
              </h4>
            </div>
            <div class="text-primary">
              <i class="ri-book-open-fill font-size-24"></i>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="col-md-6 col-xl-4">
      <div class="card">
        <div class="card-body">
          <div class="media py-2">
            <div class="media-body overflow-hidden">
              <p class="text-truncate font-size-14 mb-4">
                Total Certificates Earned By Users
              </p>
              <h4 class="afcfta_number">
                {{ entityData[0].total_certs_earned }}
              </h4>
            </div>
            <div class="text-primary">
              <i class="fa fa-certificate font-size-24"></i>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- end row -->
</template>
